import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/gloable.css';
import request from "@/utils/request";
import './utils/rem.js'; //在main.js中引入rem.js
import axios from 'axios';
import { NavBar } from 'vant';
Vue.use(NavBar);
import { Icon } from 'vant';
Vue.use(Icon);
import { NoticeBar } from 'vant';
Vue.use(NoticeBar);
import { Popup } from 'vant';
Vue.use(Popup);

Vue.config.productionTip = false

Vue.use(ElementUI,{size:"mini"});

Vue.prototype.request = request

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to,from,next) => {
  window.scrollTo(0,0);
})

